<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Контроллер
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные контроллера</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="Название"
            ></ValidationInputField>

            <ValidationSelectField
              :items="controllerType"
              rules="required"
              label="Тип контроллера"
              v-model="type"
              validate-name="Тип контроллера"
            />
            <ValidationAutocompleteField
              :search-function="objectSearch"
              disabled
              rules="required"
              label="Объект"
              v-model="object"
              validate-name="Объект"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editControllers)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { getObjectsClearRequest } from "@/helpers/api/objects";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ControllerEditPage",
  components: {
    ValidationSelectField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      controllerType: [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ],
      objectSearch: async value => {
        return (
          await getObjectsClearRequest({
            query: {
              search: value,
              limit: 10000
              // city: this.city,
              // organization: this.organization
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  created() {
    this.$store.dispatch("prepareEditController", {
      id: this.$route.params.id
    });
  },
  computed: {
    object: {
      get() {
        return this.$store.getters.getControllerEditForm.object.value;
      },
      set(newValue) {
        this.$store.commit("changeEditControllerValue", {
          fieldName: "object",
          value: newValue
        });
      }
    }, //id города
    name: {
      get() {
        return this.$store.getters.getControllerEditForm.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditControllerValue", {
          fieldName: "name",
          value: newValue
        });
      }
    }, //name,
    type: {
      get() {
        return this.$store.getters.getControllerEditForm.type.value;
      },
      set(newValue) {
        this.$store.commit("changeEditControllerValue", {
          fieldName: "type",
          value: newValue
        });
      }
    } //name
  },
  methods: {
    editControllers() {
      this.loading = true;
      this.$store
        .dispatch("editController", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.controller)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
